
import Vue, { PropType } from 'vue';
import InvoicesTable from '@/modules/invoice/views/InvoicesTable.vue';
// @ts-ignore convert candidate model to ts
import Candidate from '@/application/models/Candidate.js';
import eventBus from '@/application/eventBus';

export default Vue.extend({
  name: 'Invoices',
  components: {
    InvoicesTable,
  },
  props: {
    candidate: {
      type: Object as PropType<Candidate>,
      default: new Candidate(),
    },
  },
  created(){
    if (!this.candidate?.isZzp) {
      this.$router.push({ name: '404' });
    }
  },
  mounted() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'candidate.index' },
        text: this.$tc('candidate.title', 2),
      },
      {
        exact: true,
        to: { name: 'candidate.show' },
        text: this.candidate.name,
      },
      {
        exact: true,
        to: { name: 'candidate.invoices' },
        text: this.$tc('candidate.tabs.invoices', 2),
      }],
    );
  },
});
